"use client";

export function Image(props: { src: string }) {
  const handleDownload = async (e: React.MouseEvent) => {
    e.preventDefault();
    if (typeof window === 'undefined') return;
    try {
      const response = await fetch(props.src);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `image-${Date.now()}.png`; // Generate a unique filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Download failed:', error);
    }
  };

  return (
    <div className="relative group">
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        src={props.src}
        alt="Image"
        className="object-cover rounded-xl overflow-hidden flex-shrink-0"
      />
      <button
        onClick={handleDownload}
        className="absolute bottom-2 right-2 bg-white/80 hover:bg-white p-2 rounded-full shadow-lg transition-all opacity-0 group-hover:opacity-100"
        title="Download image"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="text-gray-700"
        >
          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
          <polyline points="7 10 12 15 17 10" />
          <line x1="12" y1="15" x2="12" y2="3" />
        </svg>
      </button>
    </div>
  );
}

export function Images(props: { images: any[] }) {
  return (
    <div className="">
      {props.images.map((image, idx) => (
        // eslint-disable-next-line jsx-a11y/alt-text
        <Image key={idx} src={image.url} />
      ))}
    </div>
  );
}

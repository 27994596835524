'use client';

import React, { useState, useEffect } from 'react';
import { PlusCircle, XCircle,  ArrowUp, ArrowDown, Trash, PenLine } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useChat } from '@/app/contexts/ChatContext';

interface Section {
  id?: string;
  title: string;
  level: number;
  subsections?: Section[];
}

interface EditableTOCProps {
  title: string;
  sections: Section[];
  targetKeyword?: string;
}

function generateId(): string {
  return Math.random().toString(36).substr(2, 9);
}

function ensureSectionIds(sections: Section[]): Section[] {
  return sections.map(section => ({
    ...section,
    id: section.id || generateId(),
    subsections: section.subsections ? ensureSectionIds(section.subsections) : undefined
  }));
}

export function EditableTOC({ title, sections: initialSections, targetKeyword = '' }: EditableTOCProps) {
  const [sections, setSections] = useState(() => ensureSectionIds(initialSections));
  const [keyword, setKeyword] = useState(targetKeyword);
  const { sendMessage } = useChat();

  const moveSection = (index: number, direction: 'up' | 'down', parentIndex?: string) => {
    setSections(prevSections => {
      const newSections = [...prevSections];
      
      if (parentIndex) {
        // Moving subsection
        const indices = parentIndex.split('.').map(i => parseInt(i) - 1);
        const parent = newSections[indices[0]];
        if (parent?.subsections) {
          const newIndex = direction === 'up' ? index - 1 : index + 1;
          if (newIndex >= 0 && newIndex < parent.subsections.length) {
            const temp = parent.subsections[index];
            parent.subsections[index] = parent.subsections[newIndex];
            parent.subsections[newIndex] = temp;
          }
        }
      } else {
        // Moving main section
        const newIndex = direction === 'up' ? index - 1 : index + 1;
        if (newIndex >= 0 && newIndex < newSections.length) {
          const temp = newSections[index];
          newSections[index] = newSections[newIndex];
          newSections[newIndex] = temp;
        }
      }
      
      return newSections;
    });
  };

  const addSection = (level: number, parentIndex?: string) => {
    const newSection: Section = {
      id: generateId(),
      title: "New Section",
      level,
      subsections: level === 1 ? [] : undefined
    };

    if (parentIndex) {
      setSections(prevSections => {
        const newSections = [...prevSections];
        const indices = parentIndex.split('.').map(i => parseInt(i) - 1);
        let current = newSections[indices[0]];
        if (current) {
          if (!current.subsections) {
            current.subsections = [];
          }
          current.subsections.push(newSection);
        }
        return newSections;
      });
    } else {
      setSections(prevSections => [...prevSections, newSection]);
    }
  };

  const deleteSection = (sectionId: string, parentIndex?: string) => {
    if (parentIndex) {
      setSections(prevSections => {
        const newSections = [...prevSections];
        const indices = parentIndex.split('.').map(i => parseInt(i) - 1);
        let current = newSections[indices[0]];
        if (current && current.subsections) {
          current.subsections = current.subsections.filter(s => s.id !== sectionId);
        }
        return newSections;
      });
    } else {
      setSections(prevSections => prevSections.filter(s => s.id !== sectionId));
    }
  };

  const handleWriteClick = () => {
    const header = "{{action%%" + "Write Article" + "}}";
    const message = `${header}
Write the article based on this structure using the writer tool:
{
  "mode": "write",
  "title": "${title}",
  "sections": ${JSON.stringify(sections, null, 2)},
  "targetKeyword": "${keyword}"
}`;
    sendMessage(message);
  };

  const TOCItem = ({ section, index, parentIndex = '' }: { section: Section; index: number; parentIndex?: string }) => {
    const currentIndex = parentIndex ? `${parentIndex}.${index + 1}` : `${index + 1}`;
    const indentLevel = section.level - 1;
    const isFirst = index === 0;
    const isLast = parentIndex 
      ? sections[parseInt(parentIndex.split('.')[0]) - 1]?.subsections?.length === index + 1
      : sections.length === index + 1;

    const [localTitle, setLocalTitle] = useState(section.title);

    // Update local title when section title changes from parent
    useEffect(() => {
      setLocalTitle(section.title);
    }, [section.title]);

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      setLocalTitle(newValue);
    };

    const handleTitleBlur = () => {
      setSections(prevSections => {
        const newSections = [...prevSections];
        if (parentIndex) {
          const indices = parentIndex.split('.').map(i => parseInt(i) - 1);
          let current = newSections[indices[0]];
          if (current && current.subsections) {
            const subsectionIndex = index;
            current.subsections[subsectionIndex].title = localTitle;
          }
        } else {
          newSections[index].title = localTitle;
        }
        return newSections;
      });
    };

    return (
      <div style={{ marginLeft: `${indentLevel * 1.5}rem` }} >
        <div className="group relative flex items-center gap-2 py-1 transition-colors rounded-md hover:bg-accent px-4 mx-2">
          <span className="text-gray-500 text-sm font-medium">{currentIndex}</span>
          <input
            type="text"
            value={localTitle}
            onChange={handleTitleChange}
            onBlur={handleTitleBlur}
            className={`
              flex-1 bg-transparent focus:outline-none focus:ring-0 cursor-text
              ${section.level === 1 ? 'text-base font-semibold' : ''}
              ${section.level === 2 ? 'text-sm font-normal' : ''}
            `}
          />
          <div className="h-8">
            <div className="flex items-center gap-1 hidden group-hover:flex">
              {!isFirst && (
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => moveSection(index, 'up', parentIndex)}
                  className="opacity-0 group-hover:opacity-100 p-1"
                  title="Move up"
                >
                  <ArrowUp className="h-4 w-4" />
                </Button>
              )}
              {!isLast && (
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => moveSection(index, 'down', parentIndex)}
                  className="opacity-0 group-hover:opacity-100 p-1"
                  title="Move down"
                >
                  <ArrowDown className="h-4 w-4" />
                </Button>
              )}
              {section.level === 1 && (
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => addSection(2, currentIndex)}
                  className="opacity-0 group-hover:opacity-100 p-1"
                  title="Add subsection"
                >
                  <PlusCircle className="h-4 w-4" />
                </Button>
              )}
              <Button
                variant="ghost"
                size="sm"
                onClick={() => deleteSection(section.id!, parentIndex)}
                className="opacity-0 group-hover:opacity-100 p-1"
                title="Delete section"
              >
                <Trash className="h-4 w-4" />
              </Button>
            </div>
          </div>
        </div>
        {section.subsections && section.subsections.length > 0 && (
          <div>
            {section.subsections.map((subsection, idx) => (
              <TOCItem
                key={subsection.id}
                section={subsection}
                index={idx}
                parentIndex={currentIndex}
              />
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="border rounded-lg shadow-sm bg-white dark:bg-black interactive-container">
      <div className="">
        <h1 className="mb-2 px-6 py-4 pb-0">
          <input
            type="text"
            value={title}
            readOnly
            className="w-full bg-transparent text-2xl font-bold focus:outline-none focus:ring-0"
          />
        </h1>
        <div className="px-6 pb-2 flex items-center text-sm">
          <span className="text-gray-500 mr-2">Target Keyword:</span>
          <input
            type="text"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            placeholder="Enter a target keyword (optional)"
            className="flex-1 bg-transparent focus:outline-none focus:ring-0 border-b border-dashed border-gray-300 pb-1"
          />
        </div>
        <div className="">
          {sections.map((section, index) => (
            <TOCItem key={section.id} section={section} index={index} />
          ))}
        </div>
        <div className="flex justify-between items-center px-2 py-2 border-t mt-4">
          <Button
            variant="ghost"
            size="sm"
            onClick={() => addSection(1)}
          >
            <PlusCircle className="h-4 w-4 mr-2" />
            <span>Add Section</span>
          </Button>
          <Button
            variant="default"
            size="sm"
            onClick={handleWriteClick}
          >
            <PenLine className="h-4 w-4 mr-2" />
            <span>Write Article</span>
          </Button>
        </div>
      </div>
    </div>
  );
} 
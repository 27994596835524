"use client";

import * as React from "react";
import { Compass, ArrowRight, X, SquareArrowOutUpRight, ExternalLink } from "lucide-react";
import { createPortal } from "react-dom";
import ReactMarkdown from "react-markdown";
import { GeistSans } from "geist/font/sans";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
} from "@/components/ui/card";

export function SearchCard({ content }: { content: any }) {
  console.log(content);

  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedUrl, setSelectedUrl] = React.useState<string | null>(null);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setSelectedUrl(null);
    }
  };

  const handleCardClick = (url: string) => {
    setSelectedUrl(url);
    setIsOpen(true);
  };

  const renderPortal = () => {
    const chatCanvas = document.getElementById("chat-canvas");
    if (!chatCanvas) return null;

    return createPortal(
      <div className="bg-background p-6 pb-0 w-[800px] h-full relative">
        <div className="absolute top-2 right-2 z-10 flex gap-2">
          <Button
            variant="outline"
            size="icon"
            className="hover:bg-muted rounded-full h-7 w-7"
            onClick={() => {
              if (selectedUrl) {
                window.open(selectedUrl, "_blank");
              }
            }}
          >
            <SquareArrowOutUpRight size={16} />
          </Button>
          <Button
            variant="outline"
            size="icon"
            onClick={toggleOpen}
            className="hover:bg-muted rounded-full h-7 w-7"
          >
            <X size={16} />
          </Button>
        </div>
        {selectedUrl && (
          <iframe
            src={selectedUrl}
            className="w-full h-full rounded-lg"
            sandbox="allow-scripts allow-same-origin"
          />
        )}
      </div>,
      chatCanvas,
    );
  };

  return (
    <div className="grid grid-cols-2 gap-2 flex-wrap max-w-[600px] px-2 mb-6">
      {content.data.map((item: any) => (
        <Card
          key={item.url}
          className=" p-3 flex items-start gap-4 hover:bg-primary/10 cursor-pointer dark:hover:bg-primary/10 transition-all duration-300 shadow-none bg-transparent"
          onClick={() => {
            window.open(item.url, "_blank");
          }}
        >
            <img
              src={`https://icons.duckduckgo.com/ip3/${
                new URL(item.url).hostname
              }.ico`}
              alt="Site favicon"
              className="w-5 h-5 rounded mt-0"
            />
          <div className="min-w-0 max-w-[calc(100%-30px)]">
            <h4 className="text-sm font-bold line-clamp-1 mt-0 mb-2">
              {item.title}
            </h4>
            <div className="flex gap-2">
              <small className="text-xs text-muted-foreground line-clamp-1">
                {item.url}
              </small>
              <ExternalLink size={14} className="ml-1 flex-shrink-0" />
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
}

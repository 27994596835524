"use client";

import * as React from "react";
import { FileText, ArrowRight, X } from "lucide-react";
import { createPortal } from "react-dom";
import ReactMarkdown from "react-markdown";
import { GeistSans } from "geist/font/sans";
import { Button } from "@/components/ui/button";

interface Document {
  id: number;
  content: string;
  metadata: string;
  similarity?: unknown;
}

interface DocumentsCardProps {
  documents: Document[];
  query: string;
}

export function DocumentsCard({ documents, query }: DocumentsCardProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [portalElement, setPortalElement] = React.useState<HTMLElement | null>(
    null,
  );

  const toggleOpen = () => {
    const newIsOpen = !isOpen;
    setIsOpen(newIsOpen);

    // Dispatch custom event to toggle panel visibility
    window.dispatchEvent(
      new CustomEvent("togglePanel", {
        detail: { isVisible: newIsOpen },
      }),
    );
  };

  // Set up portal element when isOpen changes
  React.useEffect(() => {
    if (isOpen) {
      const timeoutId = setTimeout(() => {
        const chatCanvas = document.getElementById("chat-canvas");
        setPortalElement(chatCanvas);
      }, 0);

      return () => clearTimeout(timeoutId);
    } else {
      setPortalElement(null);
    }
  }, [isOpen]);

  const renderMetadata = (metadata: string) => {
    if (!metadata) return null;

    try {
      // Attempt to parse the metadata string as JSON
      const parsedMetadata = JSON.parse(metadata);
      return (
        <div className="flex flex-wrap gap-2 text-xs text-muted-foreground mb-2">
          {Object.entries(parsedMetadata).map(([key, value]) => (
            <span key={key} className="bg-muted px-2 py-1 rounded">
              {key}: {String(value)}
            </span>
          ))}
        </div>
      );
    } catch {
      // If metadata is not valid JSON, display it as a single string
      return (
        <div className="flex flex-wrap gap-2 text-xs text-muted-foreground mb-2">
          <span className="bg-muted px-2 py-1 rounded">
            Metadata: {metadata}
          </span>
        </div>
      );
    }
  };

  const documentContent =
    portalElement &&
    isOpen &&
    createPortal(
      <div
        className={`h-full overflow-y-auto p-6 ${GeistSans.className} relative`}
      >
        <div className="flex items-center justify-between mb-6 fixed top-2 right-2">
          <Button
            variant="ghost"
            size="sm"
            onClick={toggleOpen}
            className="p-2 bg-background"
          >
            <X className="h-4 w-4" />
          </Button>
        </div>
        <div className="prose dark:prose-invert max-w-none mb-8">
          {documents.map((doc, index) => (
            <div key={doc.id || index} className="mb-8">
              {renderMetadata(doc.metadata)}
              <ReactMarkdown
                className={`prose dark:prose-invert markdown ${GeistSans.className} text-sm`}
              >
                {doc.content}
              </ReactMarkdown>
              {index < documents.length - 1 && (
                <div className="my-4 border-t border-border" />
              )}
            </div>
          ))}
        </div>
      </div>,
      portalElement,
    );

  return (
    <>
      <div className="w-full mx-2">
        <div
          onClick={toggleOpen}
          className="mt-2 mb-2 p-2 px-3 mr-3 border border-border rounded-lg truncate flex gap-4 items-center hover:bg-muted transition-colors cursor-pointer justify-between"
        >
          <div className="flex gap-2 items-center">
            <FileText size={16} />
            <h3 className="truncate text-sm">
              Query: <i>{query}</i>
            </h3>
          </div>
          <ArrowRight size={16} />
        </div>
      </div>
      {documentContent}
    </>
  );
}

"use client";

import * as React from "react";
import {
  Compass,
  ArrowRight,
  X,
  SquareArrowOutUpRight,
  ExternalLink,
} from "lucide-react";
import { createPortal } from "react-dom";
import ReactMarkdown from "react-markdown";
import { GeistSans } from "geist/font/sans";
import { Button } from "@/components/ui/button";
export function JiraCard({ content }: { content: any }) {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <>
      <div className="max-w-[600px] gap-2 mx-2 mb-4 ">
        <div
          onClick={() => {
            window.open(content.data?.url, "_blank");
          }}
          className="p-4 border border-border rounded-xl hover:bg-muted transition-colors cursor-pointer"
        >
          <div className="flex items-center gap-2 mb-2">
            <img
              src={`https://icons.duckduckgo.com/ip3/${
                content.data?.url ? new URL(content.data.url).hostname : ""
              }.ico`}
              alt="Site favicon"
              className="w-5 h-5 rounded"
            />
            <h4 className="truncate text-sm">{content.data?.title || ""}</h4>
          </div>
          <p className="text-xs text-muted-foreground">
            {content.data?.description || ""}
          </p>
          <div className="flex items-center gap-2 w-full justify-between mt-2">
            <small className="text-xs text-muted-foreground line-clamp-1 truncate">
              {content.data?.url}
            </small>
            <ExternalLink size={14} className="ml-1 flex-shrink-0" />
          </div>
        </div>
    
      </div>
    </>
  );
}

"use client";

import {
  Bar,
  Line,
  BarChart,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
} from "recharts";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { Button } from "@/components/ui/button";
import { Download } from "lucide-react";
import html2canvas from "html2canvas";
import { useRef } from "react";

interface DataPoint {
  [key: string]: string | number;
}

interface ChartProps {
  data: DataPoint[];
  dataKeys: {
    key: string;
    color: string;
    label?: string;
  }[];
  type?: "bar" | "line";
  title?: string;
  chart_legend?: string;
}

const chartConfig = {
  data: {
    label: "Value",
    color: "hsl(var(--chart-1))",
  },
};

export function Chart({
  data,
  dataKeys,
  type = "bar",
  title = "",
  chart_legend = "",
}: ChartProps) {
  const chartRef = useRef<HTMLDivElement>(null);

  const handleExport = async () => {
    if (!chartRef.current) return;

    try {
      const canvas = await html2canvas(chartRef.current, {
        backgroundColor: null,
        scale: 10,
        onclone: (clonedDoc, clonedElement) => {
          // Fix SVG font families in the cloned element
          const svgElements = clonedElement.getElementsByTagName("svg");
          for (const svg of svgElements) {
            // Set a default sans-serif font family on the SVG
            svg.style.fontFamily = "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif";
            
            // Also set it on all text elements within the SVG
            const textElements = svg.getElementsByTagName("text");
            for (const textElement of textElements) {
              textElement.style.fontFamily = "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif";
            }
            
            // And on all tspan elements
            const tspanElements = svg.getElementsByTagName("tspan");
            for (const tspanElement of tspanElements) {
              tspanElement.style.fontFamily = "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif";
            }
          }
          clonedDoc.body.style.fontFamily = "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif";
        },
      });

      const link = document.createElement("a");
      const filename = title ? 
        `${title.toLowerCase().replace(/[^a-z0-9]+/g, '-')}.png` : 
        `${type}-chart-export.png`;
      link.download = filename;
      link.href = canvas.toDataURL("image/png");
      link.click();
    } catch (error) {
      console.error("Error exporting chart:", error);
    }
  };

  const ChartComponent = type === "bar" ? BarChart : LineChart;
  const DataComponent = type === "bar" ? Bar : Line;

  const chartConfigForContainer = dataKeys.reduce((acc, { key, color, label }) => {
    acc[key] = {
      label: label || key,
      color: color,
    };
    return acc;
  }, {} as Record<string, { label: string; color: string }>);

  return (
    <div className="flex flex-col items-end gap-4 interactive-container chart-container mx-2 mb-6">
      <div
        ref={chartRef}
        className="min-h-[350px] w-full border border-border rounded-lg py-4 pr-6 pt-4 bg-background relative z-0 mt-2"
      >
        {title && <h3 className="text-lg font-bold mb-4 mx-8">{title}</h3>}
        <ChartContainer config={chartConfigForContainer} className="w-full">
          <ChartComponent data={data}>
            <CartesianGrid vertical={false} className="stroke-muted" />
            <XAxis
              dataKey="name"
              tickLine={false}
              axisLine={false}
              tickMargin={10}
              className="text-sm"
            />
            <YAxis
              tickLine={false}
              axisLine={false}
              tickMargin={10}
              className="text-sm"
            />
            <ChartTooltip content={<ChartTooltipContent />} />
            {dataKeys.map(({ key, color }) => (
              type === "bar" ? (
                <Bar key={key} dataKey={key} fill={color} radius={[4, 4, 0, 0]} />
              ) : (
                <Line key={key} dataKey={key} stroke={color} dot />
              )
            ))}
          </ChartComponent>
        </ChartContainer>
        {chart_legend && (
          <div className="text-sm text-muted-foreground mt-2 mx-10">
            {chart_legend}
          </div>
        )}
      </div>
      <Button
        onClick={handleExport}
        variant="outline"
        size="sm"
        className="flex gap-2 items-center"
      >
        <Download size={14} />
        Export as PNG
      </Button>
    </div>
  );
}

"use client";

import { useEffect, useState, useCallback, useRef } from "react";
import { createPortal } from "react-dom";
import { X, StopCircle } from "lucide-react";
import { Button } from "@/components/ui/button";
import { GeistSans } from "geist/font/sans";
import Markdown from "react-markdown";
import dynamic from "next/dynamic";
import { OutputData } from '@editorjs/editorjs';
import { EDITOR_JS_TOOLS } from "@/lib/editor-tools";
import EditorJSMarkdownConverter from '@vingeray/editorjs-markdown-converter';

const DynamicEditor = dynamic(
  () => import('./editor').then(mod => mod.Editor),
  {
    ssr: false,
    loading: () => (
      <div className="animate-pulse flex items-center justify-center">
        Loading editor...
      </div>
    )
  }
);

interface Section {
  id?: string;
  title: string;
  level: number;
  subsections?: Section[];
}

interface ArticlePortalProps {
  title: string;
  sections: Section[];
  isOpen: boolean;
  writerMeta: WriterMeta;
}

interface WriterMeta {
  model: string;
  provider: string;
  conversationId: string;
}

interface GeneratedSection {
  id: string;
  content: string;
  isGenerating: boolean;
  level: number;
  editorData?: OutputData;
  isCancelled?: boolean;
}

export function ArticlePortal({
  title,
  sections,
  isOpen = true,
  writerMeta,
}: ArticlePortalProps) {
  const [portalElement, setPortalElement] = useState<HTMLElement | null>(null);
  const [isVisible, setIsVisible] = useState(isOpen);
  const [generatedSections, setGeneratedSections] = useState<GeneratedSection[]>([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [conversionInProgress, setConversionInProgress] = useState<Set<string>>(new Set());
  const abortControllerRef = useRef<AbortController | null>(null);
  const hasStartedGeneratingRef = useRef(false);
  const editorRef = useRef<{ 
    setContent: (data: OutputData) => Promise<void>;
    save: () => Promise<OutputData>;
    appendBlocks: (blocks: OutputData['blocks']) => Promise<void>;
  } | null>(null);
  const [combinedEditorData, setCombinedEditorData] = useState<OutputData>({ 
    time: Date.now(),
    blocks: []
  });

  const handleEditorChange = useCallback(async (data: OutputData) => {
    try {
      setCombinedEditorData(data);
      
      // Convert the entire editor content back to markdown
      const markdown = data.blocks.map(block => {
        switch (block.type) {
          case 'header':
            return '#'.repeat(block.data.level || 1) + ' ' + block.data.text + '\n\n';
          case 'paragraph':
            return block.data.text + '\n\n';
          default:
            return block.data.text + '\n\n';
        }
      }).join('');

      // Update all completed sections with the new content
      setGeneratedSections(prev => prev.map(section => {
        if (!section.isGenerating && !section.isCancelled) {
          return {
            ...section,
            content: markdown
          };
        }
        return section;
      }));
    } catch (error) {
      console.error('Error updating editor:', error);
    }
  }, []);

  const updateEditorContent = useCallback(async (newData: OutputData) => {
    if (editorRef.current?.setContent) {
      await editorRef.current.setContent(newData);
    }
  }, []);

  const convertMarkdownToBlocks = useCallback(async (section: GeneratedSection) => {
    if (conversionInProgress.has(section.id)) {
      return null;
    }

    try {
      setConversionInProgress(prev => new Set(prev).add(section.id));
      console.log('Converting section to blocks:', section.id, section.content);
      
      const blocks = EditorJSMarkdownConverter.toBlocks(section.content);
      
      const editorData = {
        time: Date.now(),
        blocks
      };
      
      console.log('Conversion successful:', section.id, editorData);
      return editorData;
    } catch (error) {
      console.error('Error converting markdown to blocks:', error);
      return {
        time: Date.now(),
        blocks: [{
          type: 'paragraph',
          data: {
            text: section.content
          }
        }]
      };
    } finally {
      setConversionInProgress(prev => {
        const next = new Set(prev);
        next.delete(section.id);
        return next;
      });
    }
  }, [conversionInProgress]);

  const completeSection = useCallback(async (section: GeneratedSection) => {
    console.log('Attempting to complete section:', section.id);
    // First mark as not generating but don't set editorData yet
    setGeneratedSections(prev => {
      const updated = prev.map(s => 
        s.id === section.id 
          ? { ...s, isGenerating: false } 
          : s
      );
      console.log('Marked section as not generating:', section.id);
      return updated;
    });

    // Then start the conversion process
    const editorData = await convertMarkdownToBlocks(section);
    if (editorData) {
      console.log('Setting editor data for section:', section.id);
      
      // Add a newline block between sections if needed
      if (combinedEditorData.blocks.length > 0) {
        editorData.blocks.unshift({
          type: 'paragraph',
          data: { text: '' }
        });
      }
      
      // Append the new blocks to the editor
      if (editorRef.current?.appendBlocks) {
        await editorRef.current.appendBlocks(editorData.blocks);
      }

      // Update the state with the new blocks
      setCombinedEditorData(prev => ({
        time: Date.now(),
        blocks: [...prev.blocks, ...editorData.blocks]
      }));

      setGeneratedSections(prev => {
        const updated = prev.map(s => 
          s.id === section.id 
            ? { ...s, editorData } 
            : s
        );
        console.log('Updated sections with editor data:', updated);
        return updated;
      });
    }
  }, [convertMarkdownToBlocks, combinedEditorData]);

  // Add effect to initialize editor with title
  useEffect(() => {
    if (isVisible && !combinedEditorData.blocks.length) {
      const initialData = {
        time: Date.now(),
        blocks: []
      };
      setCombinedEditorData(initialData);
      updateEditorContent(initialData);
    }
  }, [isVisible, title, updateEditorContent]);

  useEffect(() => {
    window.dispatchEvent(
      new CustomEvent("togglePanel", {
        detail: { isVisible },
      }),
    );

    if (isVisible) {
      const timeoutId = setTimeout(() => {
        const chatCanvas = document.getElementById("chat-canvas");
        setPortalElement(chatCanvas);
      }, 0);

      return () => clearTimeout(timeoutId);
    }
  }, [isVisible]);

  useEffect(() => {
    setIsVisible(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (isVisible && generatedSections.length === 0 && !isGenerating && !hasStartedGeneratingRef.current) {
      hasStartedGeneratingRef.current = true;
      generateArticle();
    }

    return () => {
      // Cleanup function
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
        abortControllerRef.current = null;
      }
      hasStartedGeneratingRef.current = false;
    };
  }, [isVisible]);

  const stopGeneration = useCallback(() => {
    if (abortControllerRef.current) {
      console.log('Stopping generation...');
      abortControllerRef.current.abort();
      abortControllerRef.current = null;
      setIsGenerating(false);
      
      // Mark the current section as cancelled
      setGeneratedSections(prev => {
        const currentSection = prev[prev.length - 1];
        if (currentSection?.isGenerating) {
          return prev.map(s => 
            s.id === currentSection.id 
              ? { ...s, isGenerating: false, isCancelled: true }
              : s
          );
        }
        return prev;
      });
    }
  }, []);

  const generateArticle = async () => {
    try {
      setIsGenerating(true);
      setGeneratedSections([]); // Clear existing sections when starting
      abortControllerRef.current = new AbortController();
      
      const response = await fetch("/api/writer", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          title,
          sections,
          writerMeta,
        }),
        signal: abortControllerRef.current.signal
      });

      if (!response.ok) {
        throw new Error("Failed to generate article");
      }

      const reader = response.body?.getReader();
      if (!reader) return;

      const decoder = new TextDecoder();
      let currentSection: GeneratedSection = {
        id: 'title',
        content: '',
        isGenerating: true,
        level: 1
      };
      let isFirstChunk = true;
      let buffer = '';

      // Add current section to state immediately
      setGeneratedSections([currentSection]);

      const completeCurrentSection = async () => {
        if (currentSection.content) {
          console.log('Completing section:', currentSection.id, {
            content: currentSection.content.substring(0, 50) + '...'
          });
          const completedSection = { ...currentSection, isGenerating: false };
          // First update the state to mark as not generating
          setGeneratedSections(prev => {
            // Remove any duplicate sections with the same ID
            const filtered = prev.filter(s => s.id !== completedSection.id);
            return [...filtered, completedSection];
          });
          // Then convert to editor format
          await completeSection(completedSection);
        }
      };

      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          // Complete the final section before breaking
          if (currentSection.content) {
            await completeCurrentSection();
          }
          break;
        }

        const chunk = decoder.decode(value);
        buffer += chunk;

        // Update current section content immediately for streaming effect
        setGeneratedSections(prev => prev.map(s => 
          s.id === currentSection.id 
            ? { ...s, content: s.content + chunk }
            : s
        ));

        // Check for section separator or new section header
        if (buffer.includes('\n\n---\n\n') || buffer.match(/\n\n#{2,6}\s/)) {
          // Split on section separator
          const parts = buffer.split(/\n\n---\n\n|\n\n(?=#{2,6}\s)/);
          
          // Process all complete parts except the last one
          for (let i = 0; i < parts.length - 1; i++) {
            const part = parts[i];
            if (isFirstChunk) {
              currentSection.content = part;
              await completeCurrentSection();
              isFirstChunk = false;
            } else {
              // If it starts with a header, it's a new section
              const headerMatch = part.match(/^(#{2,6})\s/);
              if (headerMatch) {
                await completeCurrentSection(); // Complete previous section
                const level = headerMatch[1].length;
                const id = `section-${Date.now()}-${i}`;
                currentSection = {
                  id,
                  content: part,
                  isGenerating: true,
                  level
                };
                // Add new section immediately
                setGeneratedSections(prev => [...prev, currentSection]);
              } else {
                currentSection.content += part;
                // Update section content
                setGeneratedSections(prev => prev.map(s => 
                  s.id === currentSection.id ? { ...s, content: s.content + part } : s
                ));
              }
            }
          }

          // Keep the last part in the buffer
          buffer = parts[parts.length - 1];
        }
      }

      // Process any remaining content
      if (buffer) {
        currentSection.content += buffer;
        // Update final content before completing
        setGeneratedSections(prev => prev.map(s => 
          s.id === currentSection.id ? { ...s, content: buffer } : s
        ));
        await completeCurrentSection();
      }

    } catch (error) {
      if (error instanceof Error && error.name === 'AbortError') {
        console.log('Generation aborted by user');
        const lastSection = generatedSections[generatedSections.length - 1];
        if (lastSection?.isGenerating) {
          const completedSection = { ...lastSection, isGenerating: false };
          await completeSection(completedSection);
        }
      } else {
        console.error("Error generating article:", error);
      }
    } finally {
      setIsGenerating(false);
      abortControllerRef.current = null;
    }
  };

  const previewCard = (
    <div className="max-w-[580px] gap-2 mx-2 mb-4 w-full">
      <div
        onClick={() => setIsVisible(true)}
        className="p-4 border border-border rounded-xl hover:bg-muted transition-colors cursor-pointer"
      >
        <div className="flex items-center gap-2 mb-2">
          <h4 className="truncate text-sm font-medium">{title}</h4>
        </div>
        <p className="text-xs text-muted-foreground">
          {sections.length} sections
        </p>
        {/* Show Loading indicator if the article is still generating */}
        {isGenerating && (
          <div className="flex items-center gap-2 text-muted-foreground text-sm mt-4">
            <div className="animate-spin rounded-full h-3 w-3 border-2 border-primary border-t-transparent"></div>
            <span>Generating...</span>
          </div>
        )}
      </div>
    </div>
  );

  const articleContent =
    portalElement &&
    isVisible &&
    createPortal(
      <div className={`h-full overflow-y-auto p-6 ${GeistSans.className} relative`}>
        <div className="flex items-center justify-between mb-6 fixed top-2 right-2">
          <Button
            variant="ghost"
            size="sm"
            onClick={() => setIsVisible(false)}
            className="p-2 bg-background"
          >
            <X className="h-4 w-4" />
          </Button>
        </div>
        <div className="prose dark:prose-invert max-w-none mb-8 markdown-section duration-0">
          {/* Single Editor Instance for all completed sections */}
          <div className="mb-8">
            <DynamicEditor
              holder="main-editor"
              tools={EDITOR_JS_TOOLS}
              defaultValue={combinedEditorData}
              onChange={handleEditorChange}
              ref={editorRef}
            />
          </div>
          
          {/* Show markdown preview for sections being generated */}
          {generatedSections.map((section) => (
            section.isGenerating && (
              <div key={section.id} className="relative">
                <div className="markdown-section max-w-[650px] mx-auto opacity-60 mt-2">
                  <Markdown>{section.content}</Markdown>
                </div>
                <div className="flex items-center gap-2 text-muted-foreground text-sm mt-2 max-w-[650px] mx-auto">
                  <div className="animate-spin rounded-full h-3 w-3 border-2 border-primary border-t-transparent"></div>
                  <span>Generating...</span>
                  {!conversionInProgress.has(section.id) && (
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={stopGeneration}
                      className="ml-2 text-destructive hover:text-destructive"
                    >
                      <StopCircle className="h-4 w-4 mr-1" />
                      Stop
                    </Button>
                  )}
                </div>
              </div>
            )
          ))}
          
          {isGenerating && generatedSections.length === 0 && (
            <div className="flex items-center gap-2 text-muted-foreground text-sm">
              <div className="animate-spin rounded-full h-3 w-3 border-2 border-primary border-t-transparent"></div>
              <span>Starting article generation...</span>
            </div>
          )}
        </div>
      </div>,
      portalElement,
    );

  return (
    <>
      {previewCard}
      {articleContent}
    </>
  );
}

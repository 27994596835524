"use client";

import dynamic from "next/dynamic";
import { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { Table, ArrowRight, X, Download, Maximize2, FileSpreadsheet } from "lucide-react";
import { Button } from "@/components/ui/button";
import { textEditor } from "react-data-grid";
import { unparse } from "papaparse";
import * as XLSX from 'xlsx';

const DataSheet = dynamic(() => import("./data-sheet"), {
  ssr: false,
});

function textEditorWithPadding(props: any) {
  return <div className="px-2 h-full">{textEditor(props)}</div>;
}

interface Column {
  key: string;
  name: string;
  renderEditCell?: typeof textEditor;
}

interface SheetProps {
  columns: Column[];
  rows: any[];
  title?: string;
}

export default function SheetWrapper(props: SheetProps) {
  // Create an empty row
  const createEmptyRow = () => {
    const emptyRow: any = {};
    props.columns.forEach((col) => {
      emptyRow[col.key] = "";
    });
    return emptyRow;
  };

  // Initialize rows with an empty row if needed
  const initialRows = [...props.rows];
  if (
    !props.rows.length ||
    Object.values(props.rows[props.rows.length - 1]).some((val) => val !== "")
  ) {
    initialRows.push(createEmptyRow());
  }

  const [isFullscreen, setIsFullscreen] = useState(false);
  const [rows, setRows] = useState(initialRows);
  const [portalElement, setPortalElement] = useState<HTMLElement | null>(null);

  // Control panel visibility and dispatch events
  useEffect(() => {
    console.log("Fullscreen state changed:", isFullscreen);
    // Dispatch custom event
    window.dispatchEvent(
      new CustomEvent("togglePanel", {
        detail: { isVisible: isFullscreen },
      }),
    );

    // Wait for the panel to be available
    if (isFullscreen) {
      const timeoutId = setTimeout(() => {
        const chatCanvas = document.getElementById("chat-canvas");
        console.log("Chat canvas element (after delay):", chatCanvas);
        setPortalElement(chatCanvas);
      }, 100); // Small delay to ensure panel is mounted

      return () => {
        clearTimeout(timeoutId);
        setPortalElement(null);
      };
    }
  }, [isFullscreen]);

  // Make all columns editable by default
  const editableColumns = props.columns.map((col) => ({
    ...col,
    renderEditCell: textEditorWithPadding,
  }));

  const toggleFullscreen = () => {
    //if toggle to true, send event to close other panels
    if (!isFullscreen) {
      window.dispatchEvent(new CustomEvent("closePanel"));
    }
    setIsFullscreen(!isFullscreen);
  };

  //listen to closePanel event
  useEffect(() => {
    window.addEventListener("closePanel", () => {
      setIsFullscreen(false);
    });
  }, []);

  const handleDownloadCsv = () => {
    const csvData = rows.map((row) => {
      const rowData: any = {};
      editableColumns.forEach((col) => {
        rowData[col.name] = row[col.key];
      });
      return rowData;
    });

    const csv = unparse(csvData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", `${props.title || "data"}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadExcel = () => {
    const excelData = rows.map((row) => {
      const rowData: any = {};
      editableColumns.forEach((col) => {
        rowData[col.name] = row[col.key];
      });
      return rowData;
    });

    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `${props.title || "data"}.xlsx`);
  };

  const renderPortal = () => {
    console.log(
      "Rendering portal, isFullscreen:",
      isFullscreen,
      "portalElement:",
      portalElement,
    );
    if (!isFullscreen || !portalElement) return null;

    const portalContent = (
      <div className="bg-background flex items-start justify-center">
        <div className="relative w-full h-full">
          <div className="p-6 h-full overflow-y-auto">
            <div className="flex items-center  mb-4 gap-4">
              <Button
                variant="outline"
                size="icon"
                onClick={toggleFullscreen}
                className="hover:bg-muted rounded-full h-7 w-7"
              >
                <X size={16} />
              </Button>
              <h2 className="text-lg font-semibold">
                {props.title || "Data Sheet"}
              </h2>
            </div>

            <DataSheet
              title={props.title}
              rows={rows}
              columns={editableColumns}
              onRowsChange={setRows}
              isPortal={true}
            />
            <div className="flex justify-end gap-2 mt-4">
              <Button
                variant="outline"
                size="sm"
                onClick={handleDownloadCsv}
                className="text-muted-foreground hover:text-foreground"
              >
                <Download size={16} className="mr-2" />
                Download CSV
              </Button>
              <Button
                variant="outline"
                size="sm"
                onClick={handleDownloadExcel}
                className="text-muted-foreground hover:text-foreground"
              >
                <FileSpreadsheet size={16} className="mr-2" />
                Download Excel
              </Button>
            </div>
          </div>
        </div>
      </div>
    );

    console.log("Creating portal with content");
    return createPortal(portalContent, portalElement);
  };

  return (
    <>
      {!isFullscreen ? (
        <div className="max-w-[600px] mx-2 mt-4">
          <DataSheet
            title={props.title}
            rows={rows}
            columns={editableColumns}
            onRowsChange={setRows}
            onFullscreen={toggleFullscreen}
            isPortal={false}
          />
          <div className="flex justify-end gap-2 mt-2">
            <Button
              variant="ghost"
              size="sm"
              onClick={handleDownloadCsv}
              className="text-muted-foreground hover:text-foreground"
            >
              <Download size={16} className="mr-2" />
              CSV
            </Button>
            <Button
              variant="ghost"
              size="sm"
              onClick={handleDownloadExcel}
              className="text-muted-foreground hover:text-foreground"
            >
              <FileSpreadsheet size={16} className="mr-2" />
              Excel
            </Button>
            <Button
              variant="ghost"
              size="sm"
              onClick={toggleFullscreen}
              className="text-muted-foreground hover:text-foreground"
            >
              <Maximize2 size={16} />
            </Button>
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-2 gap-2 mx-2 w-[600px] overflow-x-auto">
          <div
            onClick={toggleFullscreen}
            className="mt-2 p-4 border border-border rounded-xl truncate flex gap-4 items-center hover:bg-muted transition-colors cursor-pointer"
          >
            <Table size={16} />
            <h3 className="truncate text-sm">
              {props.title || `Data Sheet (${rows.length} rows)`}
            </h3>
            <ArrowRight size={16} />
          </div>
        </div>
      )}
      {renderPortal()}
    </>
  );
}
